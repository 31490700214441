import React from 'react';
import { withSvgIcon } from './SvgIcon';

export const CloseIcon = withSvgIcon((props: any) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40"
    {...props}
  >
  <g id="Path_116" data-name="Path 116" fill="#fff">
    <path d="M 20 39.5 C 17.36730003356934 39.5 14.8136100769043 38.98448944091797 12.40987968444824 37.96780014038086 C 10.08788967132568 36.98567962646484 8.002429962158203 35.57958984375 6.211420059204102 33.78858184814453 C 4.42041015625 31.99757957458496 3.014319896697998 29.9121208190918 2.03220009803772 27.59012985229492 C 1.015509963035583 25.1863899230957 0.5 22.63269996643066 0.5 20 C 0.5 17.36730003356934 1.015509963035583 14.8136100769043 2.03220009803772 12.40987968444824 C 3.014319896697998 10.08788967132568 4.42041015625 8.002429962158203 6.211420059204102 6.211420059204102 C 8.002429962158203 4.42041015625 10.08788967132568 3.014319896697998 12.40987968444824 2.03220009803772 C 14.8136100769043 1.015509963035583 17.36730003356934 0.5 20 0.5 C 22.63269996643066 0.5 25.1863899230957 1.015509963035583 27.59012985229492 2.03220009803772 C 29.9121208190918 3.014319896697998 31.99757957458496 4.42041015625 33.78858184814453 6.211420059204102 C 35.57958984375 8.002429962158203 36.98567962646484 10.08788967132568 37.96780014038086 12.40987968444824 C 38.98448944091797 14.8136100769043 39.5 17.36730003356934 39.5 20 C 39.5 24.70170021057129 37.82453155517578 29.15024948120117 34.65470886230469 32.86473083496094 C 32.95108032226562 34.86444091796875 30.74987983703613 36.52672958374023 28.28879928588867 37.67219924926758 C 25.6829891204834 38.88504028320312 22.89423942565918 39.5 20 39.5 Z" stroke="none"/>
    <path d="M 20 1 C 17.4345703125 1 14.94642066955566 1.502220153808594 12.60465049743652 2.492698669433594 C 10.34226036071777 3.449611663818359 8.310220718383789 4.819721221923828 6.564968109130859 6.564968109130859 C 4.819721221923828 8.310220718383789 3.449611663818359 10.34226036071777 2.492698669433594 12.60465049743652 C 1.502220153808594 14.94642066955566 1 17.4345703125 1 20 C 1 22.5654296875 1.502220153808594 25.05358123779297 2.492698669433594 27.39535140991211 C 3.449611663818359 29.65773963928223 4.819721221923828 31.68978118896484 6.564968109130859 33.43502807617188 C 8.310220718383789 35.18027877807617 10.34226036071777 36.55038833618164 12.60465049743652 37.50730133056641 C 14.94642066955566 38.49777984619141 17.4345703125 39 20 39 C 22.82081031799316 39 25.5385799407959 38.40074920654297 28.07781982421875 37.21889877319336 C 30.47420120239258 36.10353851318359 32.61674880981445 34.48587036132812 34.27436828613281 32.54016876220703 C 37.36590957641602 28.91740989685059 39 24.58108901977539 39 20 C 39 17.4345703125 38.49777984619141 14.94642066955566 37.50730133056641 12.60465049743652 C 36.55038833618164 10.34226036071777 35.18027877807617 8.310220718383789 33.43502807617188 6.564968109130859 C 31.68978118896484 4.819721221923828 29.65773963928223 3.449611663818359 27.39535140991211 2.492698669433594 C 25.05358123779297 1.502220153808594 22.5654296875 1 20 1 M 20 0 C 31.04570007324219 0 40 8.954299926757812 40 20 C 40 24.94847106933594 38.20283126831055 29.4771900177002 35.03504943847656 33.18930053710938 C 31.55718994140625 37.27159118652344 26.09722137451172 40 20 40 C 8.954299926757812 40 0 31.04570007324219 0 20 C 0 8.954299926757812 8.954299926757812 0 20 0 Z" stroke="none" fill="#000"/>
  </g>
  <g id="Group_679" data-name="Group 679" transform="translate(15.917 16.181)">
    <line id="Line_26" data-name="Line 26" y1="8.036" x2="7.768" transform="translate(0 0)" fill="none" stroke="#000" stroke-width="1.5"/>
    <line id="Line_27" data-name="Line 27" x1="7.768" y1="8.036" transform="translate(0 0)" fill="none" stroke="#000" stroke-width="1.5"/>
  </g>
</svg>
))