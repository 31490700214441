import * as React from 'react'
import { graphql, navigate } from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import Layout from '../../components/layout'
import { Section } from '@app/components/Section'
import { CloseIcon } from '@app/components/icons/Close'
import './post-content.scss';


const BlogPost = ({ data }: any) => {
  const image = getImage(data.mdx.frontmatter.feature_image)
  const back = () => navigate(-1);

  return (
    <Layout
      // pageTitle={data.mdx.frontmatter.title}
      title={data.mdx.frontmatter.title}
      description={data.mdx.frontmatter.description}
      className="project-detail"
      navActiveElementIds={['post-detail']}
      navItems={[
        {
          name: 'homepage',
          path: '/'
        },
        {
          name: 'projects',
          path: '/projects',
          active: true
        },
      ]}
    >
      <Section
        id='introduction'
        className="introduction"
        disableAnimate
        backgroundImage={image}
        fixed
      >
        {/* <Fade bottom>
          <div className="text-white">hi, we are</div>
        </Fade>
        <h1 className="logo-text">Crystal</h1> */}
      </Section>
      <Section
        id="post-detail"
        className="first post-detail"
        heading={data.mdx.frontmatter.title}
        disableAnimate
      >
        <p className='post-detail-description'>
          {data.mdx.frontmatter.description}
        </p>
        <p className='post-detail-type'>{data.mdx.frontmatter.type}</p>
        <div className='back-button' onClick={back}>
          <div className='back-button-icon'>
            <CloseIcon
              size={3.4}
            />
          </div>
          <span className='back-button-label'>close</span>
        </div>
        <div className='post-detail-content'>
          <MDXRenderer>
            {data.mdx.body}
          </MDXRenderer>
        </div>
      </Section>
    </Layout>
  )
}

export const query = graphql`
  query($id: String) {
    mdx(id: {eq: $id}) {
      frontmatter {
        title
        description
        type
        date(formatString: "MMMM DD, YYYY")
        image_alt
        image_credit_link
        image_credit_text
        feature_image {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      body
    }
  }
`

export default BlogPost